<template>
   <VDatePicker
      class="ltr"
      v-model="date"
      :borderless="borderless"
      transparent
      :min-date="minDate"
      :color="color"
      :hide-time-header="hideDate"
      :mode="mode"
      :locale="locale"
      :masks="masks"
      @did-move="onMove"
      :expanded="expand"
      :attributes="attrs"
   />
</template>
<script>
   export default {
      name: 'SimpleCalendar',
      props: {
         borderless: {
            type: Boolean,
            default: false,
         },
         expand: {
            type: Boolean,
            default: false,
         },
         minDate: {
            type: String,
            default: new Date(),
         },
         value: {
            type: [String, Number],
            required: true,
         },
         mode: {
            type: String,
            default: 'time',
         },
         color: {
            type: String,
            default: 'blue',
         },
         hideDate: {
            type: Boolean,
            default: false,
         },
         availableDates: {
            type: Array,
            default() {
               return null;
            },
         },
      },
      data() {
         return {
            date: new Date(),
            masks: {
               input: 'YYYY-MM-DDTHH:MM',
               weekdays: 'WWW',
            },
            attrs: [
               {
                  key: 'today',
                  highlight: {
                     color: 'blue',
                     fillMode: 'outline',
                  },
                  dates: new Date(),
               },
            ],
            locale: useI18n().locale,
         };
      },
      mounted() {
         if (this.value) {
            this.date = this.value;
         } else {
            this.date = new Date();
         }

         this.$nextTick(() => {
            if (this.availableDates && this.availableDates.length) {
               let newEvent = {
                  dot: true,
                  dates: this.availableDates,
               };
               this.attrs = [{ key: 'today', highlight: false }, newEvent];
               this.date = this.$dayjs(this.availableDates[0]).format(
                  'YYYY-MM-DDTHH:MM'
               );
            }
         });
      },
      watch: {
         availableDates() {
            if (this.availableDates && this.availableDates.length) {
               let newEvent = {
                  dot: true,
                  color: 'blue',
                  dates: this.availableDates,
               };
               this.attrs = [{ key: 'today', highlight: false }, newEvent];
               this.date = this.$dayjs(this.availableDates[0]).format(
                  'YYYY-MM-DDTHH:MM'
               );
            }
         },
         date(value) {
            this.$emit(
               'selected',
               this.$dayjs(value || new Date()).format('YYYY-MM-DD')
            );
            this.$emit('update:value', value);
         },
      },
      methods: {
         onMove(events) {
            this.$emit('move', events[0]);
         },
      },
   };
</script>
<style>
   .vc-title {
      font-size: 14px;
      font-weight: 400 !important;
   }
   .vc-header {
      padding: 0px;
      margin-bottom: 20px;
   }
   .vc-weeks {
      padding: 0px;
   }
   .vc-container .vc-weekday {
      color: #616161;
      font-size: 14px;
      font-weight: 400;
   }

   .vc-blue {
      --vc-accent-400: #528cc5;
      --vc-accent-500: #95bce4;
      --vc-accent-600: #3475b2;
      --vc-accent-800: #075985;
   }
</style>
